import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { bdAxios } from '../components/utils/Axios';
import RatioForm from './forms/RatioForm';
import AdminRatio from './AdminRatio';
import ReadRatios from './ReadRatios';
import ValidateRatios from './ValidateRatios';
import { parseJwt, getToken } from '../components/utils/Common';

function Ratios() {
    const [tab, setTab] = useState<string>('saisie');
    const [content, setContent] = useState<any>('');
    const [configs, setConfigs] = useState<any>('');
    const [showAdmin, setShowAdmin] = useState<boolean>(false);

    useEffect(() => {
        const userToken: any = getToken();
        if (userToken) {
            const jwt = parseJwt(userToken);
            if (-1 !== jwt.data.roles.indexOf("administrator") /* || -1 !== jwt.data.roles.indexOf("pme_manager")*/) {
                setShowAdmin(true);
            }
        }

        bdAxios.get('ratios/configs', {
            headers: {
                'Authorization': "Bearer " + userToken
            }
        }).then((r: any) => {
            setConfigs(r.data.configs);
        });
    }, []);

    useEffect(() => {
        if (tab === "saisie") {
            setContent(<RatioForm />);
        } else if (tab === "consultation") {
            setContent(<ReadRatios configs={configs} />);
        } else if (tab === "validation") {
            setContent(<ValidateRatios />)
        } else if (tab === "admin") {
            setContent(<AdminRatio />)
        }

    }, [tab]);

    return (
        <Layout title="Accueil" classes="accueil-page">
            <div className="inner-page">
                <nav className="sidenav">
                    <ul>
                        <li className={tab === "saisie" ? "active" : ''} onClick={() => setTab('saisie')} >Saisies de données</li>
                        <li className={tab === "consultation" ? "active" : ''} onClick={() => setTab('consultation')} >Consultation des ratios</li>
                        {
                            showAdmin ?
                                <li className={tab === "admin" ? "active" : ''} onClick={() => setTab('admin')}  >Option d’administration</li>
                                : void (0)
                        }
                    </ul>
                </nav>

                <br />
                {configs ?
                    <div className="content">
                        {content ? content :
                            <div dangerouslySetInnerHTML={{ __html: configs.intro_text }}></div>
                        }
                    </div>
                    : void (0)
                }

            </div>
        </Layout>
    );
}

export default Ratios;